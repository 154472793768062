@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  width: 100%;
  overflow-x: hidden;
}

.circle svg{
  visibility: hidden;
}